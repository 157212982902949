import axios, { AxiosRequestConfig } from "axios";

const BASE_URL = "https://api-new.ngantre.com/api/";
// const BASE_URL = "https://api.ngantre.my.id/api/";

const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use(
  (config: MyAxiosRequestConfig) => {
    const pointToken = localStorage.getItem("token");
    const employeeToken = sessionStorage.getItem("token");
    const realToken = config.isEmployee ? employeeToken : pointToken;
    if (config.headers) {
      config.headers["Authorization"] = `Bearer ${realToken}`;
      config.headers["locale"] = "id";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosRequest = (
  method: "POST" | "GET" | "DELETE",
  url: string,
  config?: AxiosRequestConfig,
  data?: Object,
  isEmployee: boolean = false
) => {
  const newConfig: MyAxiosRequestConfig = {
    ...config,
    isEmployee: isEmployee,
  };
  return method === "GET"
    ? instance.get(url, newConfig)
    : method === "POST"
    ? instance.post(url, data, newConfig)
    : instance.delete(url, newConfig);
};

type MyAxiosRequestConfig = AxiosRequestConfig & {
  isEmployee?: boolean;
};
