import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { alertTypeId } from "../utils/interfaces/alert";
import { removeElementbyParent } from "../utils/helpers/removeElement";

const Alert = ({ title, message, type }: alertProps) => {
  const typeCheck = (type: alertTypeId) => {
    const color = {
      icon: "",
      divider: "",
    };

    switch (type) {
      case "1":
        color.divider = "divide-green-500";
        color.icon = "fill-green-500";
        return color;

      case "2":
        color.divider = "divide-yellow-500";
        color.icon = "fill-yellow-500";
        return color;

      case "3":
        color.divider = "divide-blue-500";
        color.icon = "fill-blue-500";
        return color;

      case "4":
        color.divider = "divide-red-500";
        color.icon = " fill-red-500";
        return color;
    }
  };

  return (
    <div
      data-testid="parent"
      className="bg-white rounded-2xl py-4 shadow self-end relative group"
    >
      <XMarkIcon
        data-testid="event"
        className="w-4 absolute top-3 right-3 opacity-0 transition group-hover:opacity-50 cursor-pointer"
        onClick={(e) => removeElementbyParent(e)}
      />
      <div
        className={`flex items-center divide-x-2 ${typeCheck(type).divider}`}
      >
        <div className="px-4">
          <InformationCircleIcon className={`w-5 ${typeCheck(type).icon}`} />
        </div>
        <div className="px-4 pr-9 ">
          <div data-testid="title" className="font-semibold text-sm">
            {title}
          </div>
          <div data-testid="msg" className="text-xs">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;

interface alertProps {
  title: string;
  message: string;
  type: alertTypeId;
}
