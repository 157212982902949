import { Navigate, Outlet, useLocation } from "react-router-dom";
import { dateDiff } from "./utils/helpers/date";

export const ProtectedAdminRoute = () => {
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");

  if (!sessionData.point) return <Navigate to={"/404"} />;

  return <Outlet />;
};

export const ProtectedRoute = ({ children }: any) => {
  const location = useLocation();

  const validateSess = () => {
    if (localStorage.getItem("token")) {
      const diff = dateDiff(localStorage.getItem("login_at") as string);
      if (diff >= 3) {
        localStorage.clear();
        return false;
      } else return true;
    } else {
      if (location.state)
        if (location.state.isEmployee) return true;
        else return false;
    }
  };

  if (validateSess()) {
    return children ? children : <Outlet />;
  } else {
    if (location.search) {
      return (
        <Navigate
          to={`/loket/login?url=${location.pathname}${location.search}`}
          replace
        />
      );
    } else return <Navigate to={`/login`} replace />;
  }
};

export const ProtectedSuperUserRoute = () => {
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");

  if (sessionData.id !== 3) return <Navigate to={"/404"} />;
  return <Outlet />;
};
