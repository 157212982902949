import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Button from "./Button";
import LoadingButton from "./LoadingButton";

interface baseModal {
  isOpen: boolean;
  cancelBtnText?: string;
  submitBtnText?: string;
  title?: string;
  isLoading?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  onClose?: () => void;
}

interface ModalConfirmProps extends baseModal {
  subTitle?: string;
}

interface modalProps extends baseModal {
  body: JSX.Element;
  submitClassBtn?: string;
  cancelClassBtn?: string;
  withBtn?: boolean;
  modalWidth?:
    | "!max-w-sm"
    | "!max-w-md"
    | "!max-w-lg"
    | "!max-w-xl"
    | "!max-w-2xl"
    | "!max-w-3xl"
    | "!max-w-4xl"
    | "!max-w-5xl";
  zIndex?: string;
}

export const Modal = (props: modalProps) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={props.isOpen} as={Fragment} data-testid="modal">
      <Dialog
        as="div"
        static
        className={`fixed z-40 ${props.zIndex} inset-0 overflow-y-auto`}
        initialFocus={cancelButtonRef}
        open={props.isOpen}
        onClose={props.setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white rounded-2xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${props.modalWidth} w-full max-w-lg`}
            >
              <div className="bg-white px-4 py-5 sm:p-6 text-left rounded-2xl relative">
                {props.title && (
                  <div className="flex items-center pb-4 gap-4 border-b px-6">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 fill-red-100 stroke-red-500"
                      aria-hidden="true"
                    />

                    <div className="mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-left text-lg leading-6 font-medium text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                    </div>
                  </div>
                )}

                <div data-testid="body" className="mt-4">
                  {props.body}
                </div>
                <div
                  onClick={() => props.setIsOpen(false)}
                  className="absolute right-6 top-6 cursor-pointer"
                >
                  <XMarkIcon className="w-5" />
                </div>
              </div>
              {props.withBtn && (
                <div className="border-t px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <Button
                    className={`${
                      props.submitClassBtn ? props.submitClassBtn + " " : ""
                    }w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-500 text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={() => {
                      props.onSubmit && props.onSubmit();
                      props.setIsOpen(false);
                    }}
                  >
                    {props.isLoading ? (
                      <LoadingButton />
                    ) : props.submitBtnText ? (
                      props.submitBtnText
                    ) : (
                      "Submit"
                    )}
                  </Button>

                  <Button
                    className={`${
                      props.cancelClassBtn ? props.cancelClassBtn + " " : ""
                    }mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:!w-auto sm:text-sm`}
                    onClick={() => props.setIsOpen(false)}
                  >
                    {props.cancelBtnText ? props.cancelBtnText : "Cancel"}
                  </Button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const ConfirmationModal = ({
  isOpen,
  subTitle,
  title,
  isLoading,
  submitBtnText = "Submit",
  cancelBtnText = "Cancel",
  setIsOpen,
  onSubmit,
}: ModalConfirmProps): JSX.Element => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all`}
                >
                  <div className="flex flex-col gap-8">
                    <div>
                      <div className="text-lg flex items-center gap-3">
                        <span className="bg-red-100 p-2 rounded-full text-red-700">
                          <ExclamationTriangleIcon className="w-5 h-5" />
                        </span>
                        <span className="font-semibold">{title}</span>
                      </div>
                      <div className=" mt-6 text-slate-600 text-center">
                        {subTitle}
                      </div>
                    </div>
                    <div className="flex items-center gap-4 justify-end ring-primary">
                      <Button
                        primary={false}
                        onClick={() => setIsOpen(false)}
                        className={"!px-4 !py-2 !text-base !h-auto !w-auto"}
                      >
                        {cancelBtnText}
                      </Button>
                      <Button
                        onClick={onSubmit}
                        className={"!px-4 !py-2 !text-base !h-auto !w-auto"}
                      >
                        {isLoading ? <LoadingButton /> : submitBtnText}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
