import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { initialPaging, pagingProps } from "../../utils/interfaces/pagination";
import { pointPropsGet } from "../../utils/interfaces/point";
import { axiosRequest } from "./axiosInstance";

export const usePoint = () => {
  const controller = new AbortController();
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
  const [point, setPoint] = useState<pointPropsGet[]>(sessionData.point);
  const [selectedPoint, setSelectedPoint] = useState<pointPropsGet>(
    sessionData.point
  );
  const [isLoading, setLoading] = useState(false);
  const [paging, setPaging] = useState<pagingProps>(initialPaging);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (
    page: number,
    searchByName?: string,
    limit: number = 7
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,
      params: {
        page: page,
        limit: limit,
        "filter[name]": searchByName,
        "append[]": "ratings",
        include: [
          "category",
          "closedTime",
          "closedDay",
          "preference",
          "youtubes",
          "owner",
        ],
      },
    };
    await axiosRequest("GET", "point", config)
      .then((v) => {
        setPoint(v.data.data);
        setPaging(v.data);
      })
      .finally(() => {
        setPageLoaded(true);
        setLoading(false);
      });
  };

  const fetchbyId = async (id: number | string) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,

      params: {
        "append[]": "ratings",
        include: [
          "category",
          "closedTime",
          "closedDay",
          "preference",
          "youtubes",
          "owner",
          "services",
        ],
      },
    };
    await axiosRequest("GET", `point/${id}`, config)
      .then((v) => setSelectedPoint(v.data))
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  const store = async (data: FormData) => {
    setLoading(true);
    await axiosRequest("POST", "point", undefined, data).then(() =>
      fetchAll(paging.current_page)
    );
  };

  const update = async (data: {}, id: number) => {
    setLoading(true);
    await axiosRequest("POST", `point/${id}`, undefined, data)
      .catch((err) => alert(err.response.data.message))
      .finally(() => setLoading(false));
    // .then(() => fetchAll())
  };

  const deleteData = async (id: number) => {
    setLoading(true);
    await axiosRequest("DELETE", `point/${id}`).then(() =>
      fetchAll(paging.current_page)
    );
  };

  return {
    point,
    selectedPoint,
    paging,
    setSelectedPoint,
    setPoint,
    fetchAll,
    fetchbyId,
    update,
    deleteData,
    store,
    setPageLoaded,
    isLoading,
    controller,
    isPageLoaded,
  };
};
