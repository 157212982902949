import moment from "moment-timezone";

export const getCurrentDay = () => {
  //**Sunday - Saturday : 0 - 6 */
  const d = new Date();
  return d.getDay();
};

export const getCurrenMonth = () => {
  const d = new Date();
  return d.getMonth();
};

export const getCurrentTime = () => {
  const d = new Date();
  const hour = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  const seconds = d.getSeconds().toString().padStart(2, "0");
  return [hour, minutes, seconds].join(":");
};

export const formatDate = (
  date: Date | string | number = new Date(),
  toUTC: boolean = false
) => {
  const d = new Date(date);
  let convertedMonth: string, convertedDay: string, convertedYear: number;

  if (toUTC) {
    convertedMonth = (d.getUTCMonth() + 1).toString().padStart(2, "0"); //**january is 0 */
    convertedDay = d.getUTCDate().toString().padStart(2, "0");
    convertedYear = d.getUTCFullYear();
  } else {
    convertedMonth = (d.getMonth() + 1).toString().padStart(2, "0");
    convertedDay = d.getDate().toString().padStart(2, "0");
    convertedYear = d.getFullYear();
  }
  return [convertedYear, convertedMonth, convertedDay].join("-");
};

export const formatTime = (date: string | Date) => {
  const d = new Date(date);
  const hour = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  const seconds = d.getSeconds().toString().padStart(2, "0");
  return [hour, minutes, seconds].join(":");
};

export const currentDate = () => {
  const d = new Date();
  let month: string, day: string, year: number;

  month = (d.getMonth() + 1).toString().padStart(2, "0");
  day = d.getDate().toString().padStart(2, "0");
  year = d.getFullYear();

  return [year, month, day].join("-");
};

export const getFirstDate = () => {
  const d = new Date();
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = "1".padStart(2, "0");
  const year = d.getFullYear();

  return [year, month, day].join("-");
};

export const convertTimezone = (timezone: string, time: string) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const rawTime = moment
    .tz(timezone)
    .set({ hours: hours, minutes: minutes, seconds: seconds });

  const convertedTime = moment(rawTime).clone().tz(localTimezone);
  return convertedTime.format("HH:mm:ss");
};

export const dateDiff = (date: string) => {
  const [y, m, d] = date.split("-").map(Number);
  const convertedDate = new Date(y, m - 1, d);
  const dateNow = new Date();
  const diffTime = Math.abs(dateNow.getTime() - convertedDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
