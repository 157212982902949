import { useState } from "react";
import { validationPropsSend } from "../../utils/interfaces/validation";
import { axiosRequest } from "./axiosInstance";

export const useQueueValidation = () => {
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const store = async (data: validationPropsSend, id: string) => {
    setLoading(true);
    await axiosRequest(
      "POST",
      `validation/validate/${id}`,
      undefined,
      data
    ).finally(() => {
      setPageLoaded(true);
      setLoading(false);
    });
  };

  return {
    store,
    isLoading,
    isPageLoaded,
  };
};
