import "./assets/css/App.css";
import { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import WithNavigationBar from "./components/base/WithNavigationBar";
import { Loader } from "./components/Loader";
import NotFound from "./components/NotFound";
import Dashboard from "./pages/admin/dashboard";
import { PageProvider } from "./utils/useContext/pageContext";
import {
  ProtectedAdminRoute,
  ProtectedRoute,
  ProtectedSuperUserRoute,
} from "./routeGuard";
import {
  routeNoAuth,
  routeAdmin,
  routesNoSidebar,
  routeSuperUser,
} from "./pages/Routes";

export const App = () => (
  <HashRouter>
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<PageProvider />}>
          <Route element={<WithNavigationBar />}>
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route element={<ProtectedAdminRoute />}>
              {routeAdmin.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    element={
                      <Suspense fallback={<Loader />}>{route.element}</Suspense>
                    }
                  />
                );
              })}
            </Route>
            <Route element={<ProtectedSuperUserRoute />}>
              {routeSuperUser.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    element={
                      <Suspense fallback={<Loader />}>{route.element}</Suspense>
                    }
                  />
                );
              })}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        {routesNoSidebar.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>{route.element}</Suspense>
              }
            />
          );
        })}
      </Route>
      {routeNoAuth.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </HashRouter>
);

export default App;
