import React from "react";

const Button = ({
  children,
  className,
  onClick,
  primary = true,
  type = "button",
  style,
  disabled = false,
}: buttonProps) => {
  const customStyle = (): string => {
    if (primary)
      return "bg-primary-500 text-white text-center flex items-center justify-center active:ring-1 ring-offset-2 ring-primary-400";
    else
      return "text-slate-400 hover:text-primary-500 border border-slate-300 hover:border-primary-400";
  };

  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${
        className ?? ""
      } ${customStyle()} rounded-full active:scale-[.98] transition py-2 px-6 font-medium hover:brightness-95 w-full h-full disabled:bg-slate-400`}
    >
      {children}
    </button>
  );
};
export default Button;

type buttonTypeProps = "button" | "submit" | "reset";
interface buttonProps {
  className?: string;
  children: JSX.Element | string;
  primary?: boolean;
  type?: buttonTypeProps;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
