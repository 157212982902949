import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
  CogIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../../utils/requests/useAuth";
import { usePageContext } from "../../../utils/useContext/pageContext";
import { usePoint } from "../../../utils/requests/usePoint";

const ProfileDropDown = () => {
  const navigate = useNavigate();
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
  const { selectedPoint: point, fetchbyId } = usePoint();
  const { logout: clearToken } = useAuth();
  const { active: activePage } = usePageContext();

  const logout = async () => {
    await clearToken();
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  let appointmentMenu: menuProps = [
    {
      name: "Keluar",
      href: logout,
      icon: ArrowRightOnRectangleIcon,
    },
  ];

  const settings = () =>
    sessionData.point ? navigate("/settings") : navigate("/settings/admin");

  if (sessionData.point) {
    menu = [
      {
        name: "Profil",
        href: () => navigate("/profile"),
        icon: UserIcon,
      },
      {
        name: "Pengaturan",
        href: settings,
        icon: CogIcon,
      },
      {
        name: "Keluar",
        href: logout,
        icon: ArrowRightOnRectangleIcon,
      },
    ];
  } else {
    menu = [
      {
        name: "Pengaturan",
        href: settings,
        icon: CogIcon,
      },
      {
        name: "Keluar",
        href: logout,
        icon: ArrowRightOnRectangleIcon,
      },
    ];
  }

  const handleMenu = () => {
    {
      /* activePage 0 = ngantre |  1 = appointment */
    }
    switch (activePage) {
      case 0:
        return menu;

      case 1:
        return appointmentMenu;

      default:
        return menu;
    }
  };

  useEffect(() => {
    sessionData.point && fetchbyId(sessionData.point.id);
  }, []);

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`${
                open ? "" : "text-opacity-90 "
              } px-3 py-2 rounded-2xl flex items-center text-base font-medium outline-none`}
            >
              <div className="mr-3">
                <p className="text-sm text-primary-500">
                  {sessionData.point ? point.name : sessionData.name}
                </p>
                <p className="text-xs text-slate-400">
                  {sessionData.point && point.category.name}
                </p>
              </div>
              <div className="mr-2">
                <img
                  className="w-10 h-10 rounded-full object-cover object-right"
                  src={sessionData.point ? point.thumb : sessionData.thumb}
                  alt="img"
                />
              </div>
              <ChevronDownIcon
                className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 px-4 right-0 sm:px-0 lg:max-w-3xl">
                {({ close }) => (
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white py-3">
                      {handleMenu().map((item) => (
                        <button
                          key={item.name}
                          onClick={() => {
                            close();
                            item.href();
                          }}
                          className="group flex items-center w-full p-2 hover:bg-primary-50 hover:text-primary-600 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex-shrink-0 w-4 h-4 group-hover:fill-primary-500">
                            <item.icon aria-hidden="true" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm">{item.name}</p>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default ProfileDropDown;
let menu: menuProps = [];

type menuProps = {
  name: string;
  href: () => void;
  icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
}[];
