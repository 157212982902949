import { formatDate, formatTime } from "../../../../../utils/helpers/date";
import { queuePropsGet } from "../../../../../utils/interfaces/queue";
import { useDashboard } from "../../../../../utils/useContext/dashboardContext";

const tableHeader = [
  "No antrean",
  "Nama",
  "Servis",
  "Data",
  "Tanggal",
] as const;

const Table = () => {
  const { setSelectedQueue, setIsOpenModalCall, fetchbyIdService, queue } =
    useDashboard();

  const handleRandomCall = async (data: queuePropsGet) => {
    setSelectedQueue(data);
    await fetchbyIdService(data.service_id);
    setIsOpenModalCall(true);
  };

  return (
    <table className="w-full text-sm relative">
      <thead className="text-slate-800 border-b sticky top-14 bg-white rounded-t-2xl">
        <tr className="text-left">
          {tableHeader.map((v, i) => (
            <th
              key={i}
              className="p-4 first:rounded-tl-2xl last:rounded-tr-2xl"
            >
              {v}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {queue.map((v) => (
          <tr
            key={v.id}
            className="hover:bg-slate-100 cursor-pointer"
            onClick={() => handleRandomCall(v)}
          >
            <td className="p-4 font-bold text-slate-800">
              {v.service && v.service.code}
              {v.number}
            </td>
            <td className="p-4">
              {v.queue_type_id === 2 ? "WEB INHOUSE" : v.user.name}
            </td>
            <td className="p-4">{v.service && v.service.name}</td>
            <td className="p-4">
              {v.popup_answer ? (
                <div className="flex flex-col gap-2">
                  {v.popup_answer.popup?.questions.map((ve) => {
                    return (
                      <div key={ve._id} className="flex gap-1 items-center">
                        <div className="text-slate-400"> {ve.question}:</div>
                        {v.popup_answer.answers.map((va) => {
                          if (ve._id === va.question_id)
                            return (
                              <div
                                key={va.question_id}
                                className="text-slate-800"
                              >
                                {" "}
                                {va.answer}
                              </div>
                            );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                "-"
              )}
            </td>
            <td className="p-4">
              <div>
                {formatDate(v.created_at)} {formatTime(v.created_at)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
