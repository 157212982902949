import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { bannerPropsGet } from "../../utils/interfaces/banner";
import { queueTypeIdProps } from "../../utils/interfaces/queue";
import { axiosRequest } from "./axiosInstance";

export const useBanner = () => {
  const controller = new AbortController();

  const [banner, setBanner] = useState<bannerPropsGet[]>();
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (isActive?: boolean, showOn?: queueTypeIdProps) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,
      params: {
        limit: 10,
        "filter[active]": isActive,
        "filter[showOn]": showOn,
      },
    };
    await axiosRequest("GET", "banner", config)
      .then((v) => setBanner(v.data.data))
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  const store = async (data: FormData) => {
    setLoading(true);
    await axiosRequest("POST", "banner", undefined, data)
      .then(() => fetchAll())
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteData = async (id: string) => {
    setLoading(true);
    await axiosRequest("DELETE", `banner/${id}`)
      .then(() => fetchAll())
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    fetchAll,
    store,
    deleteData,
    banner,
    isLoading,
    isPageLoaded,
    controller,
  };
};
