import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { initialPaging, pagingProps } from "../../utils/interfaces/pagination";
import { popupAnswerPropsGet } from "../../utils/interfaces/popup";
import { axiosRequest } from "./axiosInstance";

export const usePopupAnswer = () => {
  const controller = new AbortController();

  const session = JSON.parse(localStorage.getItem("user") || "{}");
  const [popupAnswer, setPopupAnswer] = useState<popupAnswerPropsGet[]>([]);
  const [paging, setPaging] = useState<pagingProps>(initialPaging);

  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (page: number) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,

      params: {
        page: page,
        limit: 5,
        include: ["popup", "queue", "user", "popup.service"],
        "filter[validated]": "false",
        "filter[pointId]": session.point.id,
      },
    };
    await axiosRequest("GET", "popup_answer", config)
      .then((v) => {
        setPopupAnswer(v.data.data);
        setPaging(v.data);
      })
      .finally(() => {
        setPageLoaded(true);
        setLoading(false);
      });
  };

  return {
    fetchAll,
    popupAnswer,
    paging,
    isLoading,
    isPageLoaded,
    controller,
  };
};
