import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { paginationProps } from '../utils/interfaces/pagination'

const Pagination = ({ paging, setcurrentPage }: paginationProps) => {
  return (
    <div>
      {paging.last_page > 1 &&
        <div className=" py-3 flex items-center justify-end">
          <div className="flex-1 flex justify-end sm:hidden">
            <a
              onClick={() => {
                paging.current_page == paging.current_page && setcurrentPage(paging.current_page - 1)
              }}
              className="relative inline-flex items-center px-4 py-2 border  text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </a>
            <a
              onClick={() => {
                paging.current_page != paging.current_page && setcurrentPage(paging.current_page + 1)
              }}
              className="ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </a>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
            <div>
              <nav className="relative z-0 inline-flex rounded-full shadow-sm -space-x-px hover:cursor-pointer" aria-label="Pagination">
                {paging.links.map((v, i) => (
                  <a
                    key={i}
                    onClick={() => {
                      v.url && setcurrentPage(parseInt(v.url.split('=')[1]))
                    }}
                    aria-current="page"
                    className={`relative inline-flex items-center px-4 py-2 border-x text-sm font-medium first:rounded-l-full last:rounded-r-full ${v.active ? "z-10 bg-primary-100 border-primary-100 text-primary-500" : "bg-white  text-gray-500 hover:bg-gray-50"}`}
                  >
                    {
                      i == 0 ? <ChevronLeftIcon className='w-5' /> :
                        i == paging.links.length - 1 ? <ChevronRightIcon className='w-5' />
                          : v.label
                    }
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Pagination