import { useDashboard } from "../../../../../utils/useContext/dashboardContext";

const NoQueue = () => {
  const { fetchAllQueue, currentPage } = useDashboard();

  return (
    <div className="p-4 text-base font-normal text-center flex flex-col items-center justify-center">
      <span>Belum ada antrean untuk saat ini</span>
      <span
        onClick={() => fetchAllQueue(currentPage)}
        className=" text-primary-500 cursor-pointer hover:underline decoration-primary-500"
      >
        refresh
      </span>
    </div>
  );
};

export default NoQueue;
