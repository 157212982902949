import { useNavigate } from "react-router-dom";
import DropdownButton from "../../../components/DropdownButton";
import { Loader } from "../../../components/Loader";
import { useDashboard } from "../../../utils/useContext/dashboardContext";

const Counter = () => {
  const { counterLoading, counter, queueLoading, handleCall } = useDashboard();
  const navigate = useNavigate();

  return (
    <div className="w-full self-start shrink-0 md:w-2/5 shadow rounded-2xl">
      {counter && (
        <div className="rounded-2xl bg-white">
          <div className="py-2 px-3 text-slate-800 font-semibold border-b">
            Panggil Nomor Antrean
          </div>
          <div
            id="call-container"
            className="max-h-[calc(100vh-190px)] overflow-auto "
          >
            <div className="p-2 relative mr-2">
              {counter?.length > 0 ? (
                <div className="flex flex-col gap-2">
                  {counter.map((v) => (
                    <DropdownButton
                      User={v.service && v.service.name}
                      Code={v.service && v.service.code}
                      Service={v.name}
                      key={v.id}
                      CurrentQueue={
                        v.latest_queue ? v.latest_queue?.number : "0"
                      }
                      ChildBtn={
                        <div className="flex flex-col gap-2 mt-2">
                          <button
                            role="button"
                            onClick={() => handleCall(v.id)}
                            className="w-full bg-primary-100 rounded-full p-2 transition-colors text-slate-700 hover:bg-primary-200 text-md font-medium active:ring-2 ring-offset-2 ring-primary-300"
                          >
                            Panggil
                          </button>
                          <button
                            data-testid="recall"
                            onClick={() => {
                              v.latest_queue &&
                                handleCall(v.id, v.latest_queue.id);
                            }}
                            className="w-full bg-primary-100 rounded-full p-2 transition-colors text-slate-700 hover:bg-primary-200 text-md font-medium active:ring-2 ring-offset-2 ring-primary-300"
                          >
                            Panggil Ulang
                          </button>
                        </div>
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="p-4">
                  <div>
                    Point ini belum mempunyai servis dan loketnya, yuk buat dulu
                    <span
                      className="ml-1 text-primary-500 cursor-pointer hover:underline decoration-primary-500"
                      onClick={() => navigate(`/service`)}
                    >
                      disini
                    </span>
                  </div>
                </div>
              )}
              {(counterLoading || queueLoading) && <Loader />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Counter;
