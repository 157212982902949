import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { initialPaging, pagingProps } from "../../utils/interfaces/pagination";
import { pointPropsGet } from "../../utils/interfaces/point";
import {
  callProps,
  queuePropsGet,
  queuePropsSend,
} from "../../utils/interfaces/queue";
import { rectaPrint } from "../helpers/rectaPrint";
import { axiosRequest } from "./axiosInstance";

export const useQueue = () => {
  const controller = new AbortController();

  const session = JSON.parse(localStorage.getItem("user") || "{}");
  const [queue, setQueue] = useState<queuePropsGet[]>([]);
  const [paging, setPaging] = useState<pagingProps>(initialPaging);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (page: number) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,

      params: {
        page: page,
        limit: 13,
        "filter[today_tz]": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "filter[active]": true,
        "filter[point_id]": session.point.id,
        include: ["service", "user", "popupAnswer.popup"],
      },
    };
    await axiosRequest("GET", "queue", config)
      .then((v) => {
        setQueue(v.data.data);
        setPaging(v.data);
      })
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  const store = async (
    data: Partial<queuePropsSend>,
    employeeSess?: pointPropsGet,
    isEmployee?: boolean
  ) => {
    setLoading(true);
    await axiosRequest("POST", "queue", undefined, data, isEmployee)
      .then((v) => rectaPrint(v.data, employeeSess))
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message === "Point belum buka atau sudah tutup")
          alert(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const update = async (
    data: Partial<callProps>,
    isCounter?: boolean,
    isEmployee?: boolean
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,
    };
    await axiosRequest("POST", "queue/call", config, data, isEmployee)
      .then(() => {
        !isCounter && fetchAll(paging.current_page);
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          "Nomor antrean habis atau belum ada nomor antrean"
        )
          alert(err.response.data.message);
        else {
          alert("error call");
          console.log(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const fetchQueueCounter = async (
    page: number,
    serviceId?: number,
    service_group_id?: number
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,
      params: {
        page: page,
        limit: 14,
        "filter[today]": true,
        "filter[active]": true,
        "filter[service_id]": serviceId,
        "filter[service_group_id]": service_group_id,
        include: ["service", "popupAnswer.popupQuestion.popup", "user"],
      },
    };
    await axiosRequest("GET", "queue", config)
      .then((v) => {
        setQueue(v.data.data);
        setPaging(v.data);
      })
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  return {
    store,
    fetchAll,
    update,
    queue,
    setQueue,
    fetchQueueCounter,
    paging,
    isLoading,
    isPageLoaded,
    controller,
  };
};
