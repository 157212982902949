import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";

//**active page 0 = dashboard mgantre | active page 1 = appointment */

export const PageProvider = () => {
  const active: activeProps = JSON.parse(
    localStorage.getItem("activePage") || "0"
  );

  const setActivePage = (val: number) => {
    localStorage.setItem("activePage", val.toString());
  };

  return (
    <Page.Provider
      value={{
        active,
        setActivePage,
      }}
    >
      <Outlet />
    </Page.Provider>
  );
};

const defaultVal = {
  active: 0,
  setActivePage: () => undefined,
};

const Page = createContext<{
  active: activeProps;
  setActivePage: (val: number) => void;
}>(defaultVal);

export const usePageContext = () => useContext(Page);

enum activeProps {
  ngantre = 0,
  perjanjian = 1,
}
