import { ConfirmationModal, Modal } from "../../../../../components/Modal";
import Pagination from "../../../../../components/Pagination";
import Table from "./Table";
import Err from "./Err";
import ModalBody from "./ModalBody";
import { useValidation } from "../../../../../utils/useContext/validationContext";

const ListValidation = () => {
  const {
    setcurrentPage,
    submitValidation,
    setIsOpenModalConfirmation,
    setIsOpenModalReject,
    popupAnswer,
    paging,
    isOpenModalConfirmation,
    isOpenModalReject,
  } = useValidation();

  return (
    <>
      <div className="bg-white rounded-2xl px-2 shadow ">
        <div className="flex flex-col gap-4 divide-y">
          {popupAnswer.length > 0 ? <Table /> : <Err />}
        </div>
      </div>

      <Modal
        isOpen={isOpenModalReject}
        setIsOpen={setIsOpenModalReject}
        withBtn={false}
        body={<ModalBody />}
      />

      <ConfirmationModal
        isOpen={isOpenModalConfirmation}
        setIsOpen={setIsOpenModalConfirmation}
        onSubmit={submitValidation}
        title={"Data Valid?"}
        subTitle={"Jika valid, maka pemohon ini akan mendapatkan nomor antrean"}
        submitBtnText={"Ya, Valid!"}
      />
      <Pagination paging={paging} setcurrentPage={setcurrentPage} />
    </>
  );
};

export default ListValidation;
