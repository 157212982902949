import { NavLink } from "react-router-dom";

const SidebarMenuComponent = ({ props }: sidebarMenu) => {
  return (
    <>
      {Object.keys(props).map((title, idx) => (
        <div key={idx} role="navigation">
          <h3
            data-testid="title"
            key={idx}
            className="my-2 text-xs uppercase text-slate-900 font-semibold"
          >
            {title}
          </h3>
          <div className="border-l ">
            {Object.values(props[title]).map((menu, i) => (
              <li className="my-1" key={i}>
                {menu.external_site ? (
                  <a
                    target={menu.target}
                    href={menu.path}
                    className="flex p-1 pl-3 border-l -ml-px hover:text-slate-700 hover:border-l-slate-700"
                    rel={menu.rel}
                    role="menuitem"
                  >
                    {menu.title}
                  </a>
                ) : (
                  <NavLink
                    to={menu.path}
                    target={menu.target}
                    rel={menu.rel}
                    role="menuitem"
                    className={({ isActive }) =>
                      `flex p-1 pl-3 border-l -ml-px  ${
                        isActive
                          ? "text-primary-600 font-medium  border-primary-500"
                          : "hover:text-slate-700 hover:border-l-slate-700"
                      }`
                    }
                  >
                    <span>{menu.title}</span>
                  </NavLink>
                )}
              </li>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SidebarMenuComponent;

interface sidebarMenu {
  props: {
    [index: string]: {
      title: string;
      path: string;
      external_site?: boolean;
      target?: string;
      rel?: string;
    }[];
  };
}
