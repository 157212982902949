import { Tab } from "@headlessui/react";
import { ValidationProvider } from "../utils/useContext/validationContext";
import { ReactNode } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (obj: tabProps) => {
  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-full bg-white p-1 shadow">
          {Object.values(obj).map((value, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium rounded-full  focus:outline-none ",
                  selected
                    ? "text-slate-50 bg-primary-500/90 hover:bg-primary-500"
                    : "hover:bg-primary-100"
                )
              }
            >
              {value.title}
            </Tab>
          ))}
        </Tab.List>
        {Object.values(obj).map((v, i) => {
          if (v.isValidation) {
            return (
              <ValidationProvider key={i}>
                <Tab.Panels className="mt-2">
                  <Tab.Panel
                    className={classNames(
                      "focus:outline-none focus:ring-0 ring-offset-0 ring-white ring-opacity-60"
                    )}
                  >
                    {v.content}
                  </Tab.Panel>
                </Tab.Panels>
              </ValidationProvider>
            );
          } else {
            return (
              <Tab.Panels key={i} className="mt-2">
                <Tab.Panel
                  className={classNames(
                    "focus:outline-none focus:ring-0 ring-offset-0 ring-white ring-opacity-60"
                  )}
                >
                  {v.content}
                </Tab.Panel>
              </Tab.Panels>
            );
          }
        })}
      </Tab.Group>
    </div>
  );
};
export default Tabs;

interface tabProps {
  [key: string | number]: {
    title: ReactNode;
    content: ReactNode;
    isValidation?: boolean;
  };
}
