export const mainMenu = {
  Utama: [
    {
      title: "Dashboard",
      path: "/dashboard",
    },
  ],
  Manajemen: [
    {
      title: "Servis",
      path: "/service",
    },
    {
      title: "Konter / Loket",
      path: "/counter",
    },
    {
      title: "Pegawai",
      path: "/employee",
    },
    {
      title: "Service Group",
      path: "/group",
    },
    {
      title: "Popup Service",
      path: "/popup",
    },
  ],
  Link: [
    {
      title: "Ambil Antrean",
      path: "/queue",
    },
    {
      title: "Display Antrean",
      path: "/display",
    },
    {
      title: "Petugas Loket",
      path: "/loket/login",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ],
  Laporan: [
    {
      title: "Log Antrean",
      path: "/queue-logs",
    },
    {
      title: "Jumlah Antrean",
      path: "/queue-total",
    },
  ],
  Tutorial: [
    {
      title: "Menambah Pegawai",
      external_site: true,
      target: "_blank",
      rel: "noopener noreferrer",
      path: "https://drive.google.com/file/d/1yPabxag4yn7J-3pDAQ52Ue4Jd2s7cPLd/view?usp=share_link",
    },
    {
      title: "Login ke Loket",
      external_site: true,
      target: "_blank",
      rel: "noopener noreferrer",
      path: "https://drive.google.com/file/d/1PXVrdFsvtyDGo8sP7vIFmsOOoQ-4F8KX/view?usp=share_link",
    },
  ],
};

export const mainMenuU = {
  "": [
    {
      title: "Dashboard",
      path: "/user/dashboard",
    },
    // {
    //   title: 'Buat Point',
    //   path: '/user/point',
    // },
  ],
};

export const mainMenuSU = {
  "": [
    {
      title: "Monitor Antrean",
      path: "/super-user/monitor",
    },
    {
      title: "List User",
      path: "/super-user/list-user",
    },
    {
      title: "List Point",
      path: "/super-user/list-point",
    },
    {
      title: "Playlist",
      path: "/super-user/playlist",
    },
    {
      title: "Broadcast",
      path: "/super-user/broadcast",
    },
    {
      title: "Kategori",
      path: "/super-user/category",
    },
    {
      title: "Alert",
      path: "/super-user/alert",
    },
    {
      title: "Banner Iklan",
      path: "/super-user/banner",
    },
    {
      title: "Log Antrean",
      path: "/queue-logs/0",
    },
    {
      title: "Jumlah Antrean",
      path: "/queue-total/0",
    },
  ],
};

export const appointmentMenu = {
  Utama: [
    {
      title: "Dashboard",
      path: "/appointment/dashboard",
    },
  ],
  Manajemen: [
    {
      title: "Servis",
      path: "/appointment/service",
    },
    {
      title: "Form",
      path: "/appointment/form",
    },
  ],
  // Manajemen: [
  //   {
  //     title: "Servis",
  //     path: "/service",
  //   },
  //   {
  //     title: "Konter / Loket",
  //     path: "/counter",
  //   },
  //   {
  //     title: "Pegawai",
  //     path: "/employee",
  //   },
  //   {
  //     title: "Service Group",
  //     path: "/group",
  //   },
  //   {
  //     title: "Popup Service",
  //     path: "/popup",
  //   },
  // ],
  // Link: [
  //   {
  //     title: "Ambil Antrean",
  //     path: "/queue",
  //   },
  //   {
  //     title: "Display Antrean",
  //     path: "/display",
  //   },
  //   {
  //     title: "Petugas Loket",
  //     path: "/loket/login",
  //     target: "_blank",
  //     rel: "noopener noreferrer",
  //   },
  // ],
  // Laporan: [
  //   {
  //     title: "Log Antrean",
  //     path: "/queue-logs",
  //   },
  //   {
  //     title: "Jumlah Antrean",
  //     path: "/queue-total",
  //   },
  // ],
  // Tutorial: [
  //   {
  //     title: "Menambah Pegawai",
  //     external_site: true,
  //     target: "_blank",
  //     rel: "noopener noreferrer",
  //     path: "https://drive.google.com/file/d/1yPabxag4yn7J-3pDAQ52Ue4Jd2s7cPLd/view?usp=share_link",
  //   },
  //   {
  //     title: "Login ke Loket",
  //     external_site: true,
  //     target: "_blank",
  //     rel: "noopener noreferrer",
  //     path: "https://drive.google.com/file/d/1PXVrdFsvtyDGo8sP7vIFmsOOoQ-4F8KX/view?usp=share_link",
  //   },
  // ],
};
