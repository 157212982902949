import { useValidation } from "../../../../../utils/useContext/validationContext";

const Err = () => {
  const { fetchAll, currentPage } = useValidation();
  return (
    <div className="p-4 text-base font-normal text-center flex flex-col items-center justify-center">
      <span>Belum ada Validasi data untuk saat ini</span>
      <span
        onClick={() => fetchAll(currentPage)}
        className=" text-primary-500 cursor-pointer hover:underline decoration-primary-500"
      >
        refresh
      </span>
    </div>
  );
};

export default Err;
