import { Loader } from "../../../../../components/Loader";
import { Modal } from "../../../../../components/Modal";
import Pagination from "../../../../../components/Pagination";
import { useDashboard } from "../../../../../utils/useContext/dashboardContext";
import ModalBody from "./ModalBody";
import NoQueue from "./NoQueue";
import Table from "./Table";

const ListQue = () => {
  const {
    setcurrentPage,
    setIsOpenModalCall,
    queuePaging,
    queue,
    isOpenModalCall,
    selectedQueue,
    queueLoading,
  } = useDashboard();

  return (
    <div className="flex flex-col w-full mt-2 self-start max-h-[calc(100vh-180px)] ">
      <div className="bg-white rounded-2xl px-2 pb-2 shadow ">
        <div className="flex flex-col gap-4 divide-y relative">
          {queue.length > 0 ? <Table /> : <NoQueue />}
          {queueLoading && <Loader />}
        </div>
      </div>
      <Pagination paging={queuePaging} setcurrentPage={setcurrentPage} />
      <Modal
        isOpen={isOpenModalCall}
        setIsOpen={setIsOpenModalCall}
        title={`Panggil ${
          selectedQueue?.service && selectedQueue?.service.code
        }${selectedQueue?.number} melalui konter`}
        withBtn={false}
        body={<ModalBody />}
      />
    </div>
  );
};

export default ListQue;
