export const handleSidebarView = ({
  isSidebarOpen,
  refs,
  isMobile,
}: toggleSidebarProps) => {
  if (!isSidebarOpen) {
    if (isMobile) {
      refs.current?.classList.remove("-ml-64");
      refs.current?.classList.add("ml-0");
    } else {
      refs.current?.classList.add("md:-ml-64");
      refs.current?.classList.remove("md:ml-0");
    }
  } else {
    if (isMobile) {
      refs.current?.classList.add("-ml-64");
      refs.current?.classList.remove("ml-0");
    } else {
      refs.current?.classList.remove("md:-ml-64");
      refs.current?.classList.add("md:ml-0");
    }
  }
};

export const handleMainView = ({
  isSidebarOpen,
  refs,
  isMobile,
}: toggleSidebarProps) => {
  if (!isSidebarOpen) {
    if (isMobile) {
      refs.current?.classList.remove("ml-0");
      refs.current?.classList.add("ml-64");
    } else {
      refs.current?.classList.add("md:ml-0");
      refs.current?.classList.remove("md:ml-64");
    }
  } else {
    if (isMobile) {
      refs.current?.classList.add("ml-0");
      refs.current?.classList.remove("ml-64");
    } else {
      refs.current?.classList.remove("md:ml-0");
      refs.current?.classList.add("md:ml-64");
    }
  }
};

export const handleProfileView = ({
  isMobile,
  isSidebarOpen,
  refs,
}: toggleSidebarProps) => {
  if (!isSidebarOpen) {
    if (isMobile) {
      refs.current?.classList.remove("mr-0");
      refs.current?.classList.add("mr-64");
    } else {
      refs.current?.classList.add("md:mr-0");
      refs.current?.classList.remove("md:mr-64");
    }
  } else {
    if (isMobile) {
      refs.current?.classList.add("mr-0");
      refs.current?.classList.remove("mr-64");
    } else {
      refs.current?.classList.remove("md:mr-0");
      refs.current?.classList.add("md:mr-64");
    }
  }
};

type toggleSidebarProps = {
  refs: React.RefObject<HTMLDivElement>;
  isSidebarOpen: boolean;
  isMobile: boolean;
};
