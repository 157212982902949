import Tabs from "../../../components/Tabs";
import { useTitle } from "../../../utils/helpers/useTitle";
import { DashboardProvider } from "../../../utils/useContext/dashboardContext";
import Carousel1 from "./Carousel1";
import Counter from "./Counter";
import ListQue from "./tabs/queue";
import ListValidation from "./tabs/validation";

const Dashboard = () => {
  useTitle("Ngantre.com | Admin Dashboard");

  return (
    <DashboardProvider>
      <div className="text-slate-700 text-lg font-semibold">Dashboard</div>
      <Carousel1 />
      <div className="flex flex-col md:flex-row my-5 relative gap-4">
        <Counter />
        <Tabs {...tabsComponents} />
      </div>
    </DashboardProvider>
  );
};

export default Dashboard;
const tabsComponents = {
  1: {
    title: "Antrean",
    content: <ListQue />,
  },
  2: {
    title: (
      <div className="flex gap-1 justify-center items-center">
        <span>Validasi</span>
        <span
          className="w-6 h-5 bg-red-500 rounded-full hidden !text-white"
          id="badge"
        />
      </div>
    ),
    content: <ListValidation />,
    isValidation: true,
  },
};
