import { AxiosRequestConfig } from "axios";
import { resetPasswordProps } from "../../utils/interfaces/resetPassword";
import { axiosRequest } from "./axiosInstance";

export const useAuth = () => {
  const login = async (data: { email: string; password: string }) =>
    await axiosRequest("POST", "auth/login", undefined, data);

  const logout = async (isEmployee?: boolean) =>
    await axiosRequest(
      "DELETE",
      "auth/logout",
      undefined,
      undefined,
      isEmployee
    );

  const sendReset = async (email: string) => {
    const config: AxiosRequestConfig = {
      params: {
        email: email,
      },
    };
    return await axiosRequest("GET", "password/send_reset", config);
  };

  const resetPassword = async (data: resetPasswordProps) =>
    await axiosRequest("POST", "password/reset", undefined, data);

  return {
    sendReset,
    login,
    logout,
    resetPassword,
  };
};
