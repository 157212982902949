import { useRef, useState, useEffect } from "react";
import Sidebar2 from "./sidebar/Sidebar2";
import Navbar2 from "./navbar/Navbar2";
import { Outlet } from "react-router-dom";
import Notifications from "./Notifications";
import { useMediaQuery } from "react-responsive";
import { handleMainView, handleSidebarView } from "./handleToggleSidebar";

const WithNavigationBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const side = useRef<HTMLDivElement>(null);
  const main = useRef<HTMLDivElement>(null);
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  useEffect(() => {
    handleSidebarView({ isMobile, isSidebarOpen, refs: side });
    handleMainView({ isMobile, isSidebarOpen, refs: main });
  }, [isMobile, isSidebarOpen]);

  return (
    <>
      <div className={`flex h-full`}>
        <div
          ref={side}
          className={`transition-all duration-500 ease-in-out w-64 fixed -ml-64 md:ml-0 h-full border-r z-50 md:z-40
          `}
        >
          <Sidebar2 />
        </div>
        <div
          ref={main}
          className={`transition-all duration-500 ease-in-out flex flex-1 flex-col ml-0 md:ml-64

          `}
        >
          <Navbar2
            isSidebarOpen={isSidebarOpen}
            setSidebar={setSidebarOpen}
            isMobile={isMobile}
          />

          <div className="pt-20 h-full relative">
            <div className={`container h-full mx-auto px-4`}>
              {sessionData.id !== 3 && <Notifications />}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {isMobile && !isSidebarOpen && (
        <div
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          className="absolute bg-red-100 inset-0 z-40 backdrop-blur-sm bg-white/30"
        />
      )}
    </>
  );
};

export default WithNavigationBar;
