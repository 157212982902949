import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loader } from "../../components/Loader";
import { counterPropsGet } from "../../utils/interfaces/counter";
import { initialPaging, pagingProps } from "../../utils/interfaces/pagination";
import { callProps, queuePropsGet } from "../../utils/interfaces/queue";
import { currentDate } from "../helpers/date";
import { pusher } from "../helpers/pusher";
import { useCounter } from "../requests/useCounter";
import { useQueue } from "../requests/useQueue";
import { Channel } from "pusher-js";
import { useNavigate } from "react-router-dom";

const defaultVal = {
  counter: [],
  counterLoading: false,
  selectedCounter: [],
  selectedQueue: undefined,
  queuePaging: initialPaging,
  isOpenModalCall: false,
  currentPage: 1,
  queue: [],
  queueLoading: false,
  setcurrentPage: () => undefined,
  setQueue: () => undefined,
  setIsOpenModalCall: () => undefined,
  fetchAllCounter: async () => undefined,
  fetchbyIdService: async () => undefined,
  fetchAllQueue: () => undefined,
  handleCall: () => undefined,
  setSelectedQueue: () => undefined,
};

type contextType = {
  counterLoading: boolean;
  counter: counterPropsGet[] | undefined;
  selectedCounter: counterPropsGet[] | undefined;
  selectedQueue: queuePropsGet | undefined;
  queuePaging: pagingProps;
  currentPage: number;
  queue: queuePropsGet[];
  queueLoading: boolean;
  isOpenModalCall: boolean;
  setcurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenModalCall: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAllCounter: () => Promise<void>;
  fetchbyIdService: (
    id: number,
    pointId?: string | number | undefined
  ) => Promise<void>;
  fetchAllQueue: (page: number) => void;
  setQueue: React.Dispatch<React.SetStateAction<queuePropsGet[]>>;
  handleCall: (counterId: number, queueId?: number) => void;
  setSelectedQueue: React.Dispatch<
    React.SetStateAction<queuePropsGet | undefined>
  >;
};

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const {
    counter,
    isLoading: counterLoading,
    fetchAll: fetchAllCounter,
    isPageLoaded: isLoadedCounter,
    counterByIdService: selectedCounter,
    fetchbyIdService,
    controller: controllerCounter,
  } = useCounter();
  const {
    fetchAll: fetchAllQueue,
    isLoading: queueLoading,
    queue,
    setQueue,
    update,
    paging: queuePaging,
    isPageLoaded: isLoadedQueue,
    controller: controllerQueue,
  } = useQueue();
  const navigate = useNavigate();
  let channel: Channel;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [isOpenModalCall, setIsOpenModalCall] = useState<boolean>(false);

  const [selectedQueue, setSelectedQueue] = useState<queuePropsGet>();

  const handleCall = async (counterId: number, queueId?: number) => {
    const data: callProps = {
      _method: "PUT",
      counter_id: counterId,
      queue_id: queueId,
    };
    await update(data);
    setIsOpenModalCall(false);
    fetchAllCounter();
  };

  const handlePusher = (queue: queuePropsGet) => {
    if (queue.queue_date === currentDate()) {
      setQueue((oldData) => [...oldData, queue]);
    }
  };

  useEffect(() => {
    isLoadedCounter && isLoadedQueue && setPageLoaded(true);
  }, [isLoadedCounter, isLoadedQueue]);

  useEffect(() => {
    const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
    if (sessionData.id !== 3) {
      channel = pusher.subscribe(`queue-${sessionData.point.id}`);

      fetchAllCounter();
      channel.bind("queued", (data: { queue: queuePropsGet }) =>
        handlePusher(data.queue)
      );
    } else navigate(`/super-user/monitor`, { replace: true });

    return () => {
      sessionData.id !== 3 && channel.unbind("queued");
      controllerQueue.abort();
      controllerCounter.abort();
    };
  }, []);

  useEffect(() => {
    fetchAllQueue(currentPage);
  }, [currentPage]);

  if (pageLoaded) {
    return (
      <Dashboard.Provider
        value={{
          counterLoading,
          counter,
          currentPage,
          selectedCounter,
          selectedQueue,
          queue,
          queuePaging,
          queueLoading,
          isOpenModalCall,
          setQueue,
          setIsOpenModalCall,
          fetchAllCounter,
          fetchAllQueue,
          setcurrentPage,
          fetchbyIdService,
          handleCall,
          setSelectedQueue,
        }}
      >
        {children}
      </Dashboard.Provider>
    );
  }
  return <Loader />;
};

const Dashboard = createContext<contextType>(defaultVal);

export const useDashboard = () => useContext(Dashboard);
