import { createContext, useContext, useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { initialPaging, pagingProps } from "../../utils/interfaces/pagination";
import { popupAnswerPropsGet } from "../../utils/interfaces/popup";
import { validationPropsSend } from "../../utils/interfaces/validation";
import { usePopupAnswer } from "../requests/usePopupAnswer";
import { useQueueValidation } from "../requests/useQueueValidation";
import { useDashboard } from "./dashboardContext";
import { ReactNode } from "react";

const defaultVal = {
  isOpenModalReject: false,
  isOpenModalConfirmation: false,
  paging: initialPaging,
  popupAnswer: [],
  currentPage: 1,
  isLoading: false,
  setcurrentPage: () => undefined,
  submitValidation: async () => undefined,
  setIsOpenModalConfirmation: () => undefined,
  setIsOpenModalReject: () => undefined,
  fetchAll: async () => undefined,
  setSelectedValidation: () => undefined,
};

type contextType = {
  isOpenModalReject: boolean;
  isOpenModalConfirmation: boolean;
  paging: pagingProps;
  popupAnswer: popupAnswerPropsGet[];
  currentPage: number;
  isLoading: boolean;
  setcurrentPage: React.Dispatch<React.SetStateAction<number>>;
  submitValidation: (approve?: boolean, reason?: string) => Promise<void>;
  setIsOpenModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenModalReject: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAll: (page: number) => Promise<void>;
  setSelectedValidation: React.Dispatch<
    React.SetStateAction<popupAnswerPropsGet | undefined>
  >;
};

export const ValidationProvider = ({ children }: { children: ReactNode }) => {
  const [selectedValidation, setSelectedValidation] =
    useState<popupAnswerPropsGet>();
  const [currentPage, setcurrentPage] = useState(1);
  const [isOpenModalReject, setIsOpenModalReject] = useState(false);
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
  const { isLoading, isPageLoaded, popupAnswer, fetchAll, paging, controller } =
    usePopupAnswer();
  const { fetchAllQueue, queuePaging } = useDashboard();
  const { store } = useQueueValidation();

  const submitValidation = async (approve: boolean = true, reason?: string) => {
    if (selectedValidation) {
      const obj: validationPropsSend = {
        _method: "PUT",
        rejected: !approve,
        reject_reason: reason,
      };
      await store(obj, selectedValidation._id);
      handleBadge();
      fetchAllQueue(queuePaging.current_page);
      fetchAll(currentPage);
      setIsOpenModalReject(false);
      setIsOpenModalConfirmation(false);
    }
  };

  const handleBadge = (): void => {
    const badge = document.getElementById("badge") as HTMLElement;

    if (paging.total > 0) {
      if (popupAnswer.length > 0) {
        paging.total > 9
          ? (badge.innerText = "9+")
          : (badge.innerText = paging.total.toString());
        badge.classList.remove("hidden");
      }
    } else badge.classList.add("hidden");
  };

  useEffect(() => {
    handleBadge();
  }, [popupAnswer]);

  useEffect(() => {
    fetchAll(currentPage);
    return () => {
      controller.abort();
    };
  }, [currentPage]);

  if (isPageLoaded) {
    return (
      <Validation.Provider
        value={{
          isOpenModalConfirmation,
          isOpenModalReject,
          paging,
          popupAnswer,
          currentPage,
          isLoading,
          setcurrentPage,
          submitValidation,
          setIsOpenModalConfirmation,
          setIsOpenModalReject,
          fetchAll,
          setSelectedValidation,
        }}
      >
        {children}
      </Validation.Provider>
    );
  }
  return <Loader />;
};

const Validation = createContext<contextType>(defaultVal);

export const useValidation = () => useContext(Validation);
