// import { useDebouncedCallback } from "../../../utils/helpers/debounce";
import { usePageContext } from "../../../utils/useContext/pageContext";
import { appointmentMenu, mainMenu, mainMenuSU, mainMenuU } from "./mainMenu";
import SidebarMenuComponent from "./SidebarMenuComponent";
// import { useNavigate } from "react-router-dom";

const Sidebar2 = () => {
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
  const { active: activePage, setActivePage } = usePageContext();

  /* activePage 0 = ngantre |  1 = appointment */

  // const navigate = useNavigate();

  // const debounceGoto = useDebouncedCallback(() => {
  //   goto();
  // }, 800);

  // const goto = () => {
  //   setActivePage(1 - activePage);
  //   switch (activePage) {
  //     case 0:
  //       navigate("/appointment/dashboard"); //go to this url
  //       navigate(0); //and then refresh, so the side/nav bar get updated too
  //       break;

  //     case 1:
  //       navigate("/dashboard"); //go to this url
  //       navigate(0); //and then refresh, so the side/nav bar get updated too
  //       break;
  //   }
  // };

  const ngantreNav = () => {
    if (sessionData.point != null) {
      return mainMenu;
    } else if (sessionData.id === 3) {
      return mainMenuSU;
    } else return mainMenuU;
  };
  const appointmentNav = () => appointmentMenu;

  return (
    <nav className="flex flex-col lg:w-64 sm:max-w-xs bg-white h-full relative">
      <div className="flex w-full items-center px-6 py-6">
        <img
          className="w-full h-full object-cover"
          alt="logo"
          src={
            activePage === 0
              ? "/qrbarcode.png"
              : "/c7373a9558cd51e014eaa63364e4ff88.jpg"
          }
        />
      </div>
      <div className="px-4 pb-6 overflow-y-auto">
        <ul className="mb-8 text-sm">
          <SidebarMenuComponent
            props={activePage === 0 ? ngantreNav() : appointmentNav()}
          />
        </ul>
      </div>
      {/* <div className=" absolute bottom-0 px-4 py-2 inset-x-0 bg-white border-t">
        <div className=" hover:text-primary-500 cursor-pointer group inline">
          <ArrowLeftIcon className="w-5 inline mb-0.5" />
          <div
            onClick={debounceGoto}
            className="group-hover:underline decoration-primary-500 ml-1.5 inline"
          >
            Ke halaman {activePage === 0 ? "perjanjian" : "antrean"}
          </div>
        </div>
      </div> */}
    </nav>
  );
};
export default Sidebar2;
