import { Transition } from "@headlessui/react";
import { ChevronUpIcon, UserIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const DropdownButton = (props: dropDownButton) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full px-4 py-2 text-sm font-medium text-slate-50 bg-primary-500/90 rounded-2xl hover:bg-primary-500 "
      >
        <div className="mr-1 text-left">
          <div className="flex items-center gap-1">
            <UserIcon className="w-4 inline-block" /> {props.User}
          </div>
          <span>
            {props.Service} # {props.Code}
            {props.CurrentQueue}
          </span>
        </div>
        <ChevronUpIcon
          className={`flex-shrink-0 ${
            isOpen ? "transform rotate-180" : ""
          } w-5 h-5 text-white`}
        />
      </button>
      <div onClick={() => setIsOpen(!isOpen)} className="mx-2">
        <Transition
          show={isOpen}
          enter="transition-all origin-top duration-200"
          enterFrom="invisible opacity-0 max-h-0"
          enterTo="visible opacity-100 max-h-36"
          leave="transition-all origin-top ease-out duration-200"
          leaveFrom="visible opacity-100 max-h-36"
          leaveTo="invisible opacity-0 max-h-0"
        >
          {props.ChildBtn}
        </Transition>
      </div>
    </div>
  );
};

export default DropdownButton;

interface dropDownButton {
  Code?: string;
  Service: string;
  User?: string;
  CurrentQueue: number | string;
  ChildBtn: JSX.Element;
}
