import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import {
  counterPropsGet,
  counterPropsSend,
} from "../../utils/interfaces/counter";
import { axiosRequest } from "./axiosInstance";

export const useCounter = () => {
  const controller = new AbortController();

  const session = JSON.parse(localStorage.getItem("user") || "{}");
  const [counter, setCounter] = useState<counterPropsGet[]>();
  const [counterByIdService, setCounterByIdService] =
    useState<counterPropsGet[]>();
  const [selectedCounter, setSelectedCounter] = useState<counterPropsGet>();
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (pointId?: number | string, slug?: string) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,

      params: {
        "filter[point_id]": pointId ? pointId : session.point.id,
        "filter[service_group]": slug,
        append: ["latest_queue"],
        include: ["service"],
      },
    };
    await axiosRequest("GET", "counter", config)
      .then((v) => setCounter(v.data))
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  const fetchbyId = async (id: number, pointId?: number | string) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,

      params: {
        "filter[point_id]": pointId,
        append: ["latest_queue"],
        include: ["service", "queue"],
      },
    };
    await axiosRequest("GET", `counter/${id}`, config)
      .then((v) => setSelectedCounter(v.data))
      .finally(() => setLoading(false));
  };

  const fetchbyIdService = async (id: number, pointId?: number | string) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      params: {
        "filter[service_id]": id,
        "filter[point_id]": pointId ? pointId : session.point.id,
        include: ["service", "queue"],
      },
    };
    await axiosRequest("GET", "counter", config)
      .then((v) => setCounterByIdService(v.data))
      .finally(() => setLoading(false));
  };

  const store = async (data: Partial<counterPropsSend>) => {
    setLoading(true);
    await axiosRequest("POST", "counter", undefined, data).then(() =>
      fetchAll()
    );
  };

  const update = async (data: Partial<counterPropsSend>, id: number) => {
    setLoading(true);
    await axiosRequest("POST", `counter/${id}`, undefined, data).then(() =>
      fetchAll()
    );
  };

  const deleteData = async (id: number) => {
    setLoading(true);
    await axiosRequest("DELETE", `counter/${id}`).then(() => fetchAll());
  };

  return {
    counter,
    selectedCounter,
    counterByIdService,
    fetchbyId,
    fetchbyIdService,
    deleteData,
    setSelectedCounter,
    store,
    update,
    fetchAll,
    isLoading,
    isPageLoaded,
    controller,
  };
};
