import { lazy } from "react";

const SelectDashboard = lazy(() => import("./selectDashboard"));

const AppointmentDashboard = lazy(() => import("./appointment/dashboard"));
const AppointmentForms = lazy(() => import("./appointment/form"));
const AppointmentCreateForm = lazy(
  () => import("./appointment/form/createForm")
);
const AppointmentService = lazy(() => import("./appointment/service"));
const AppointmentCheckForm = lazy(() => import("./appointment/checkForm"));
const AppointmentChat = lazy(() => import("./appointment/chat"));

const Point = lazy(() => import("./user/point"));
const CheckQueue = lazy(() => import("./user/checkQueue"));

const Dashboard = lazy(() => import("./admin/dashboard"));
const QueueInhouse = lazy(() => import("./admin/qinhouse"));
const QueueDisplay = lazy(() => import("./admin/qdisplay"));
const Service = lazy(() => import("./admin/service"));
const ServiceGroup = lazy(() => import("./admin/serviceGroup"));
const Profile = lazy(() => import("./admin/profile"));
const Settings = lazy(() => import("./admin/settings"));
const Counter = lazy(() => import("./admin/counter"));
const CreateCounter = lazy(() => import("./admin/counter/createCounter"));
const Employee = lazy(() => import("./admin/employee"));
const QueueLogs = lazy(() => import("./admin/reports/queueLogs"));
const QueueCount = lazy(() => import("./admin/reports/queueCount"));
const Popup = lazy(() => import("./admin/popup"));
const CreatePopup = lazy(() => import("./admin/popup/createPopup"));
const DashboardLoket = lazy(() => import("./admin/dashboardCounter/dashboard"));
const SelectCounter = lazy(
  () => import("./admin/dashboardCounter/selectCounter")
);

const Monitor = lazy(() => import("./superAdmin/monitor"));
const ListUser = lazy(() => import("./superAdmin/listUser"));
const ListPoint = lazy(() => import("./superAdmin/listPoint"));
const Playlist = lazy(() => import("./superAdmin/playlist"));
const Broadcast = lazy(() => import("./superAdmin/broadcast"));
const Category = lazy(() => import("./superAdmin/category"));
const Ads = lazy(() => import("./superAdmin/banner"));
const Alert = lazy(() => import("./superAdmin/alert"));

const Login = lazy(() => import("./auth/login"));
const Register = lazy(() => import("./auth/register"));
const SendForget = lazy(() => import("./auth/forgotPassword"));
const ResetPassword = lazy(() => import("./auth/forgotPassword/ResetPassword"));

export const routeSuperUser = [
  { path: "/super-user/monitor", element: <Monitor /> },
  { path: "/super-user/list-user", element: <ListUser /> },
  { path: "/super-user/list-point", element: <ListPoint /> },
  { path: "/super-user/playlist", element: <Playlist /> },
  { path: "/super-user/broadcast", element: <Broadcast /> },
  { path: "/super-user/category", element: <Category /> },
  { path: "/super-user/banner", element: <Ads /> },
  { path: "/super-user/alert", element: <Alert /> },
  { path: "/user/point/:id", element: <Point /> },
  { path: "/queue-logs/:id", element: <QueueLogs /> },
  { path: "/queue-total/:id", element: <QueueCount /> },
  { path: "/settings/admin", element: <Settings /> },
];

export const routeAdmin = [
  { path: "/counter", element: <Counter /> },
  { path: "/counter/create", element: <CreateCounter /> },
  { path: "/counter/edit/:id", element: <CreateCounter /> },
  { path: "/service", element: <Service /> },
  { path: "/group", element: <ServiceGroup /> },
  { path: "/profile", element: <Profile /> },
  { path: "/settings", element: <Settings /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/queue-logs", element: <QueueLogs /> },
  { path: "/queue-total", element: <QueueCount /> },
  { path: "/popup", element: <Popup /> },
  { path: "/popup/create", element: <CreatePopup /> },
  { path: "/popup/edit/:id", element: <CreatePopup /> },
  { path: "/employee", element: <Employee /> },

  { path: "/appointment/dashboard", element: <AppointmentDashboard /> },
  { path: "/appointment/service", element: <AppointmentService /> },
  { path: "/appointment/form", element: <AppointmentForms /> },
  { path: "/appointment/form/create", element: <AppointmentCreateForm /> },
  { path: "/appointment/check/:id", element: <AppointmentCheckForm /> },
  { path: "/appointment/chat/:id", element: <AppointmentChat /> },
];

export const routesNoSidebar = [
  { path: "/admin/select-dashboard", element: <SelectDashboard /> },
  { path: "/queue", element: <QueueInhouse /> },
  { path: "/display", element: <QueueDisplay /> },
  { path: "/loket/dashboard", element: <SelectCounter /> },
  { path: "/loket/dashboard/:id", element: <DashboardLoket /> },
];

export const routeNoAuth = [
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/forgot-password", element: <SendForget /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/display/:id/:group", element: <QueueDisplay /> },
  { path: "/display/:id", element: <QueueDisplay /> },
  { path: "/display/login", element: <Login /> },
  { path: "/queue/:id", element: <QueueInhouse /> },
  { path: "/queue/:id/:group", element: <QueueInhouse /> },
  { path: "/loket/login", element: <Login /> },
  { path: "/queue/login", element: <Login /> },
  { path: "/check/:id", element: <CheckQueue /> },
];
