import Button from "../../../../../components/Button";
import { Loader } from "../../../../../components/Loader";
import { formatDate, formatTime } from "../../../../../utils/helpers/date";
import { useValidation } from "../../../../../utils/useContext/validationContext";

const Table = () => {
  const {
    setIsOpenModalConfirmation,
    setIsOpenModalReject,
    setSelectedValidation,
    popupAnswer,
    isLoading,
  } = useValidation();

  const tableHeader = ["Identitas", "Data", "Tanggal", ""];
  return (
    <table className="w-full text-sm relative">
      <thead className="capitalize text-slate-800 border-b sticky top-14 bg-white rounded-t-2xl">
        <tr className="text-left">
          {tableHeader.map((v, i) => (
            <th
              key={i}
              className="p-3 first:rounded-tl-2xl last:rounded-tr-2xl"
            >
              {v}
            </th>
          ))}
        </tr>
      </thead>
      <tbody id="r" className="divide-y divide-gray-200 relative">
        {isLoading && <Loader />}
        {popupAnswer.map((v) => (
          <tr key={v._id}>
            <td>
              <div>
                {" "}
                Nama :{v.queue_type_id === 2 ? "WEB INHOUSE" : v.user.name}
              </div>
              <div> Email : {v.user.email} </div>
              <div> Telp : {v.user.phone} </div>
            </td>
            <td className="p-3 ">
              <div>
                Mengantre pada:
                <span className="text-slate-800 font-semibold">
                  {" "}
                  {v.popup.service.name}
                </span>
              </div>
              <div>
                {v.popup?.questions.map((ve) => {
                  return (
                    <div key={ve._id} className="flex justify-between">
                      <div className="flex gap-1">
                        <div className="text-slate-400"> {ve.question}:</div>
                        {v.answers.map((va) => {
                          if (ve._id === va.question_id)
                            return (
                              <div
                                key={va.question_id}
                                className="text-slate-800"
                              >
                                {va.answer === null
                                  ? ""
                                  : typeof va.answer === "string"
                                  ? va.answer
                                  : va.answer.join(", ")}
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </td>
            <td className="p-3 ">
              <div>
                {formatDate(v.created_at)} {formatTime(v.created_at)}
              </div>
            </td>
            <td className="p-3 ">
              <div className="flex flex-col gap-2">
                <Button
                  onClick={() => {
                    setSelectedValidation(v);
                    setIsOpenModalConfirmation(true);
                  }}
                >
                  Valid
                </Button>
                <Button
                  onClick={() => {
                    setSelectedValidation(v);
                    setIsOpenModalReject(true);
                  }}
                >
                  Tidak Valid
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
