import Recta from "recta";
import "moment/locale/id";
import moment from "moment";
import { pointPropsGet } from "../../utils/interfaces/point";
import { convertPlatformUser } from "./convertPlatformUser";

export const rectaPrint = (data: any, point?: pointPropsGet) => {
  const sessionData = JSON.parse(localStorage.getItem("user") || "{}");
  const namaPoint = point ? point.name : sessionData.point.name;
  const idPoint = point ? point.id : sessionData.point.id;
  var printer = new Recta(idPoint, 1811);
  printer.open().then(function () {
    printer
      .align("center")
      .mode("A", true, true, false, false)
      .text("Ngantre.com")
      .feed(1)
      .mode("A", true, false, false, false)
      .text(`${namaPoint}`)
      .mode("A", false, false, false, false)
      .text("---------------------------------")
      .bold(true)
      .text(`${data.service.name}`)
      .mode("A", false, false, false, false)
      .text("NOMOR ANTREAN :")
      .mode("A", true, true, true, false)
      .text(`${data.service.code}${data.number}`)
      .mode("A", false, false, false, false)
      .mode("B", false, false, false, false)
      .text(`Antre Via: ${convertPlatformUser(data.queue_type_id)}`)
      .mode("A", false, false, false, false)
      .text(moment(`${data.created_at}`).format("dddd LL LTS"))
      .text("---------------------------------")
      .font("B")
      .text("Download Aplikasinya di Google Playstore")
      .feed(7)
      .cut(false, 0)
      .print();
  });
};
