export type pagingProps = {
  current_page: number;
  data: any[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: {
    active: boolean;
    label: string;
    url?: string;
  }[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url?: string;
  to: number;
  total: number;
};

export type paginationProps = {
  paging: pagingProps;
  setcurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export const initialPaging = {
  current_page: 1,
  data: [],
  first_page_url: "",
  from: 0,
  last_page: 0,
  last_page_url: "",
  links: [
    {
      active: false,
      label: "",
      url: "",
    },
  ],
  next_page_url: "",
  path: "",
  per_page: 0,
  prev_page_url: "",
  to: 0,
  total: 0,
};
