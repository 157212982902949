import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { alertPropsGet, alertPropsSend } from "../../utils/interfaces/alert";
import { queueTypeIdProps } from "../../utils/interfaces/queue";
import { axiosRequest } from "./axiosInstance";

export const useAlert = () => {
  const controller = new AbortController();
  const [alert, setAlert] = useState<alertPropsGet[]>();
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);

  const fetchAll = async (
    limit?: number,
    isActive?: boolean,
    showOn?: queueTypeIdProps
  ) => {
    setLoading(true);
    const config: AxiosRequestConfig = {
      signal: controller.signal,
      params: {
        limit: limit,
        "filter[active]": isActive,
        "filter[showOn]": showOn,
      },
    };
    await axiosRequest("GET", "alert", config)
      .then((v) => setAlert(v.data.data))
      .finally(() => {
        setLoading(false);
        setPageLoaded(true);
      });
  };

  const store = async (data: alertPropsSend) => {
    setLoading(true);
    await axiosRequest("POST", "alert", undefined, data)
      .then(() => fetchAll())
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteData = async (id: string) => {
    setLoading(true);
    await axiosRequest("DELETE", `alert/${id}`)
      .then(() => fetchAll())
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    fetchAll,
    store,
    deleteData,
    alert,
    isLoading,
    isPageLoaded,
    controller,
  };
};
