export const convertPlatformUser = (id?: number) => {
  switch (id) {
    case 1:
      return "Mobile app";
    case 2:
      return "Web inhouse";
    case 3:
      return "Qr code";
    case 4:
      return "Mobile inhouse";
    case 5:
      return "Web app";
    default:
      return "Web inhouse";
  }
};
