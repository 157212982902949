import { Fragment, useEffect } from "react";
import Alert from "../Alert";
import { useAlert } from "../../utils/requests/useAlert";

const Notifications = () => {
  const { fetchAll, alert } = useAlert();

  useEffect(() => {
    fetchAll(20, true, 2);
  }, []);

  return (
    <div className="absolute right-4 z-30 max-w-[40%] ">
      <div className="flex flex-col gap-y-4 ">
        {alert &&
          alert.map((v) => (
            <Fragment key={v._id}>
              <Alert message={v.message} title={v.title} type={v.type} />
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default Notifications;
