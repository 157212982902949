import { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Loader } from "../../../components/Loader";
import { useBanner } from "../../../utils/requests/useBanner";

const Carousel1 = () => {
  const { banner, isLoading, fetchAll, controller } = useBanner();
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    if (banner) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === banner.length - 1 ? 0 : prevIndex + 1
          ),
        4000
      );
    }
    return () => {
      resetTimeout();
    };
  }, [index]);

  const prevSlide = () => {
    if (banner) {
      const isFirstSlide = index === 0;
      const newIndex = isFirstSlide ? banner.length - 1 : index - 1;
      setIndex(newIndex);
    }
  };

  const nextSlide = () => {
    if (banner) {
      const isLastSlide = index === banner.length - 1;
      const newIndex = isLastSlide ? 0 : index + 1;
      setIndex(newIndex);
    }
  };

  useEffect(() => {
    fetchAll(true, 2);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {banner && banner.length > 0 && (
        <div className="w-full mt-4">
          <div className="z-20 relative group">
            <div className="z-10 opacity-0 group-hover:opacity-100 absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer duration-300 ease-in-out transition-opacity">
              <ArrowLeftIcon className="w-7" onClick={prevSlide} />
            </div>
            <div className="z-10 opacity-0 group-hover:opacity-100 absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer duration-300 ease-in-out transition-opacity">
              <ArrowRightIcon className="w-7" onClick={nextSlide} />
            </div>
            <div className="my-0 mx-auto overflow-hidden rounded-2xl">
              <div
                className="whitespace-nowrap ease-out duration-1000 h-[250px]"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
              >
                {!isLoading ? (
                  banner.map((v, index) => (
                    <div
                      className="inline-block h-full w-full rounded-2xl bg-center bg-contain bg-no-repeat bg-slate-100 "
                      key={index}
                      style={{ backgroundImage: `url(${banner[index].image})` }}
                    />
                  ))
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <div className="absolute bottom-1 left-1 opacity-0 group-hover:opacity-100 duration-300 ease-in-out transition-opacity">
              {banner &&
                banner.map((_, idx) => (
                  <div
                    key={idx}
                    className={`inline-block w-2 h-2 rounded-full cursor-pointer mx-0.5 ${
                      index === idx ? " bg-slate-50" : " bg-white/50"
                    }`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel1;
