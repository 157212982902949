import React, { useRef, useState, useEffect } from "react";
import ProfileDropDown from "./ProfileDropDown";
import { handleProfileView } from "../handleToggleSidebar";
import { usePageContext } from "../../../utils/useContext/pageContext";

const Navbar2 = ({ isMobile, isSidebarOpen, setSidebar }: refProps) => {
  // const { active: activePage } = usePageContext();
  const profile = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleProfileView({ isMobile, isSidebarOpen, refs: profile });
  }, [isMobile, isSidebarOpen]);

  return (
    <section className="z-40 fixed h-14 w-full bg-white border-b border-l flex items-center px-4">
      <button
        className="navbar-burger flex items-center rounded focus:ring-2 focus:ring-white"
        onClick={() => setSidebar(!isSidebarOpen)}
      >
        <svg
          className="text-slate-500 bg-white hover:bg-primary-400 hover:text-white block h-8 w-8 p-2 rounded"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
      {/* {!isMobile && (
        <div className="text-lg font-semibold ml-2 text-primary-500">
          {activePage === 0 ? "Ngantre" : "Perjanjian"}
        </div>
      )} */}
      <div
        ref={profile}
        className={` transition-all duration-500 ease-in-out ml-auto mr-0 md:mr-64`}
      >
        <ProfileDropDown />
      </div>
    </section>
  );
};

export default Navbar2;

interface refProps {
  isSidebarOpen: boolean;
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}
