import Button from "../../../../../components/Button";
import { Loader } from "../../../../../components/Loader";
import { useDashboard } from "../../../../../utils/useContext/dashboardContext";

const ModalBody = () => {
  const { handleCall, counterLoading, selectedQueue, selectedCounter } =
    useDashboard();
  return (
    <div className="mb-6 flex flex-wrap justify-center items-center gap-4 lg:gap-8 content-center h-full">
      {selectedCounter && selectedCounter.length > 0 ? (
        selectedCounter.map((v) => (
          <Button
            key={v.id}
            onClick={() => handleCall(v.id, selectedQueue?.id)}
            className={`!w-full h-16 md:w-1/3 lg:w-1/4 `}
          >
            <span className="line-clamp-2 text-lg">{v.name}</span>
          </Button>
        ))
      ) : (
        <span className="text-center">
          Servis ini belum memiliki konter, silahkan buat konter terlebih dulu
        </span>
      )}
      {counterLoading && <Loader />}
    </div>
  );
};

export default ModalBody;
