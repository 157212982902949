import { useRef, useState } from "react";
import Button from "../../../../../components/Button";
import { useValidation } from "../../../../../utils/useContext/validationContext";

const ModalBody = () => {
  const { submitValidation } = useValidation();
  const rejection = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const handleRejection = () => {
    if (!rejection.current?.value) setError("Alasan Tolak tidak boleh Kosong");
    submitValidation(false, rejection.current?.value);
  };

  return (
    <div>
      <div className="text-center font-semibold">
        Tuliskan Alasan mengapa permohonan ini tidak Valid
      </div>
      <div className="my-6">
        <input ref={rejection} type="text" onChange={() => setError("")} />
        {error && <span className="text-sm text-red-500">{error}</span>}
      </div>
      <Button onClick={() => handleRejection()}>Submit</Button>
    </div>
  );
};

export default ModalBody;
